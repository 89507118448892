'use strict';

const POBUCA_WEB_PUSH_HEADERS = {
    'Content-Type': 'application/json',
};

const POBUCA_WEB_PUSH_USER_SETTINGS_MODEL = ['TENANT_ID', 'SITE_ID', 'PUBLIC_KEY'];

const POBUCA_WEB_PUSH_SETTINGS = {
    SERVER_URL: 'https://webapi.loyalty.pobuca.com/api/WebPush',
    API_VERSION: '1.0',
    SW_PATH: ''
}

class EncryptionService {
    urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
    
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
    
        return outputArray;
    }
}

class WebPushService {
    #settings;
    #isSubscribed;
    #swRegistration;
    #encryptionService;

    constructor(encryptionService) {
        this.#isSubscribed = false;
        this.#swRegistration = null;
        this.#encryptionService = encryptionService;
    }

    async #saveSubscriptionToServer(subscription) {
        const { SERVER_URL, API_VERSION, TENANT_ID, SITE_ID } = this.#settings;

        const SUBSCRIPTION_URL = `${SERVER_URL}/subscribe?api-version=${API_VERSION}&x-tenant-id=${TENANT_ID}`;
    
        const subscriptionBody = {
            siteId: SITE_ID,
            subscription
        };
    
        const response = await fetch(SUBSCRIPTION_URL, {
            method: 'post',
            headers: POBUCA_WEB_PUSH_HEADERS,
            body: JSON.stringify(subscriptionBody),
        });
    
        return response.json();
    }
    
    async #removeSubscriptionFromServer(endpoint) {
        const { SERVER_URL, API_VERSION, TENANT_ID } = this.#settings;

        const SUBSCRIPTION_URL = `${SERVER_URL}/unsubscribe?api-version=${API_VERSION}&x-tenant-id=${TENANT_ID}`;
    
        const body = { endpoint };
    
        const response = await fetch(SUBSCRIPTION_URL, {
            method: 'post',
            headers: POBUCA_WEB_PUSH_HEADERS,
            body: JSON.stringify(body)
        });
    
        return response.json();
    }

    async #updateSubscriptionOnServer(subscription) {
        if (subscription) await this.#saveSubscriptionToServer(subscription);
    }

    async subscribeUser(subscription) {
        if (this.#isSubscribed) {
            console.log('User IS subscribed.');
            this.#updateSubscriptionOnServer(subscription);
        }
        else {
            console.log('User is NOT subscribed.');
    
            try {
                const applicationServerKey = this.#encryptionService.urlB64ToUint8Array(this.#settings.PUBLIC_KEY);
        
                const subscription = await this.#swRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
                });
        
                console.log('User is subscribed.');
        
                await this.#updateSubscriptionOnServer(subscription);
        
                this.#isSubscribed = true;
            } catch (err) {
                console.log('Failed to subscribe the user: ', err);
            }
        }
    }

    unsubscribeUser() {
        let endpoint;
        
        this.#swRegistration.pushManager.getSubscription()
            .then((subscription) => {
                endpoint = subscription.endpoint;
    
                if(subscription) subscription.unsubscribe();
            })
            .catch((error) => console.warn('Error unsubscribing', error))
            .then(() => {
                this.#removeSubscriptionFromServer(endpoint);
    
                console.log('User is unsubscribed.');
    
                this.#isSubscribed = false;
            });
    }

    #notSupportedErrorHandler() {
        console.warn('Service Worker and/or Push messaging is not supported');
    }

    checkIfBrowserDeniesPermissions() {
        return Notification.permission === 'denied';
    }

    async checkIfUserIsSubscribed() {
        const { SERVER_URL, API_VERSION, TENANT_ID, SW_PATH } = this.#settings;

        const isSubscribed = await navigator.serviceWorker.register(`${SW_PATH || 'sw.js'}?serverUrl=${SERVER_URL}&apiVersion=${API_VERSION}&tenantId=${TENANT_ID}`)
            .then((swReg) => {
                console.log('Service Worker is registered', swReg);

                this.#swRegistration = swReg;
                
                return this.#swRegistration.pushManager.getSubscription()
                    .then((subscription) => {
                        return !!subscription;
                    });
            })
            .catch((error) => {
                console.error('Service Worker Error', error);
            });
        
        return isSubscribed;
    }

    async checkIfClientShouldConsent() {
        const isUserSubscribed = await this.checkIfUserIsSubscribed();
        return !isUserSubscribed && !this.checkIfBrowserDeniesPermissions();
    }

    async #initiatePushServices() {
        console.log('Service Worker and Push are supported');

        const { SERVER_URL, API_VERSION, TENANT_ID } = this.#settings;
    
        await navigator.serviceWorker.register(`sw.js?serverUrl=${SERVER_URL}&apiVersion=${API_VERSION}&tenantId=${TENANT_ID}`)
            .then((swReg) => {
                console.log('Service Worker is registered', swReg);

                this.#swRegistration = swReg;
                
                this.#swRegistration.pushManager.getSubscription()
                    .then((subscription) => {
                        this.#isSubscribed = !!subscription;
                    });
            })
            .catch((error) => {
                console.error('Service Worker Error', error);
            });
    }

    #setSettings(settings) {
        let missingOptions = [];

        for(let option of POBUCA_WEB_PUSH_USER_SETTINGS_MODEL) {
            if(!settings[option]) missingOptions.push(option);
        }

        const errorMessage = () => `${missingOptions.join(', ')} ${missingOptions.length === 1 ? 'is' : 'are'} missing.`;

        if(missingOptions.length) return console.warn(errorMessage());

        this.#settings  = { ...POBUCA_WEB_PUSH_SETTINGS, ...settings };
    }

    startPobucaWebPushMessages(settings) {
        this.#setSettings(settings);
        if (!('serviceWorker' in navigator) || !('PushManager' in window)) this.#notSupportedErrorHandler();
        else this.#initiatePushServices();
    }
}

const encryptionService = new EncryptionService;

const webPushService = new WebPushService(encryptionService);
window.webPushService = webPushService;
export default webPushService;
